import axios from "axios";

const url = `${process.env.VUE_APP_FUSION_API_URL}/`;
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
// const url = `http://3plapi.test/`
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"

const api = "API-KEY";
const warehouseAPI = `${process.env.VUE_APP_WAREHOUSE_API_URL}/billing/`;
const AUTH_TOKEN = process.env.VUE_APP_WAREHOUSE_API_AUTH_TOKEN
// let warehouseAPI = `http://node_backend.test/api/v1/billing/`

axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

export default {
  async getBillingData(company) {
    let endpoint = buildUrl(company);
    if (endpoint === "notSetupYet")
      throw Error(
        "This company has not been setup yet for the Billing Console."
      );
    if (!endpoint) throw Error("Please choose a Company from the menu.");
    let billingUrl = `${url}welcome/${endpoint}?${api}=${API_KEY}`;
    let res = await axios.get(billingUrl);
    return res.data.data;
  },
  async getBillingDataDaterange(company, dateShippedFrom, dateShippedTo) {
    if (!dateShippedFrom) throw Error("Please select a date shipped from.");
    if (!dateShippedTo) throw Error("Please select a date shipped to.");
    let url = buildUrl(company, dateShippedFrom, dateShippedTo);
    if (url === "notSetupYet")
      throw Error(
        "This company has not been setup yet for the Billing Console."
      );
    if (!url) throw Error("Please choose a Company from the menu.");
    let res = await axios.get(url);
    return res.data.data;
  },
  async getGenericBilling(companyId, dateFrom, dateTo, prepId = false){
    if (!dateFrom) throw Error("Please select a date shipped from.");
    if (!dateTo) throw Error("Please select a date shipped to.");
    if (!companyId) throw Error("No company ID provided");
    let url = `${warehouseAPI}generic-billing?dateFrom=${dateFrom}&dateTo=${dateTo}&companyId=${companyId}&timestamp=${new Date().getTime()}`;
    if(prepId){
      url += `&prepId=${prepId}`
    }
    let res = await axios.get(url);
    return res.data.data;
  },
  async getCompanyRates(companyId, prepId = false){
    if (!companyId) throw Error("No company ID provided");
    // let testUrl = `http://node_backend.test/api/v1/billing/`
    // let url = `${testUrl}get-rates?companyId=${companyId}`;
    let url = `${warehouseAPI}get-rates?companyId=${companyId}&timestamp=${new Date().getTime()}`;
    if(prepId){
      url += `&prepId=${prepId}`
    }
    let res = await axios.get(url);
    return res.data.data;
  },
  async getProductBillingCharges(companyCode){
    let url = `${warehouseAPI}product-billing-charge?companyCode=${companyCode}&timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async zeroPickChargesAlert(companyCode, invoiceNumber){
    let url = `${process.env.VUE_APP_WAREHOUSE_API_URL}/utilities/cron-jobs/zero-pick-charges-alert`;
    let res = await axios.get(url, {params: {companyCode: companyCode, invoiceNumber: invoiceNumber }});
    return res.data.data;
  },
  async saveProductBillingCharge(formData){
    if (!formData) throw Error("Error getting form data");
    let url = `${warehouseAPI}product-billing-charge`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async saveCompanyRates(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    // let testUrl = `http://node_backend.test/api/v1/billing/`
    // let url = `${testUrl}save-rates`;
    let url = `${warehouseAPI}save-rates`;
    let res = await axios.post(url, formData);
    return res.data.data;
  },
  async getConsumables(){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}get-consumables`;
    let res = await axios.get(url, {params: {timestamp: new Date().getTime()}});
    return res.data.data;
  },
  async getStorageSizes(){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}get-storage-sizes`;
    let res = await axios.get(url, {params: {timestamp: new Date().getTime()}});
    return res.data.data;
  },
  async saveConsumables(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}save-consumables`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async saveStorageSizes(formData){
    if (!formData) throw Error("Error getting form data");
    let url = `${warehouseAPI}save-storage-sizes`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async deleteConsumable(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}delete-consumable`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async deleteStorageSize(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}delete-storage-size`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async getStorageTariffs(){
    let url = `${warehouseAPI}get-storage-tariffs`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async getGeneratedBillingQuery(companyCode, prepId = false){
    let url = `${warehouseAPI}/print-generic-billing-query?companyCode=${companyCode}&timestamp=${new Date().getTime()}`;
    if(prepId){
      url += `prepId=${prepId}`
    }
    let res = await axios.get(url);
    return res.data.data;
  },
  async getPrepCompanies(companyCode){
    let builtUrl = `${url}companies/prep_companies?${api}=${API_KEY}&companyCode=${companyCode}`;
    let res = await axios.get(builtUrl);
    return res.data.data;
  },
  async getCompanyBillingCredit(companyId){
    let companyIdParam = companyId ? companyId : ''
    let url = `${warehouseAPI}company-billing-credit?companyId=${companyIdParam}&timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async getCurrentCompanyBillingCredit(companyId){
    let url = `${warehouseAPI}current-company-billing-credit?companyId=${companyId}&timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async insertCompanyBillingCredit(reqBody) {
    let res = await axios.post(`${warehouseAPI}company-billing-credit`, reqBody);
    return res.data.data;
  },
  async deleteCompanyBillingCredit(reqBody) {
    let res = await axios.delete(`${warehouseAPI}company-billing-credit`, {data:reqBody});
    return res.data.data;
  },
  async getUncommittedInvoiceNumbers(){
    let url = `${warehouseAPI}uncommitted-invoice-numbers?timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async getUncommittedBillingData(invoiceNumber, companyId){
    let url = `${warehouseAPI}uncommitted-billing-data?invoiceNumber=${invoiceNumber}&companyId=${companyId}&timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async getSubmittedCompaniesByInvoiceNumber(invoiceNumber){
    let url = `${warehouseAPI}submitted-companies-by-invoice-number?invoiceNumber=${invoiceNumber}&timestamp=${new Date().getTime()}`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async deleteUncommittedBillingData(reqBody) {
    let res = await axios.delete(`${warehouseAPI}uncommitted-billing-data`, {data:reqBody});
    return res.data.data;
  },
  async deleteBillingDataByClientReference(reqBody) {
    let res = await axios.delete(`${warehouseAPI}billing-data-by-client-reference`, {data:reqBody});
    return res.data.data;
  },
  async getBillingChargesByOrderNumber(orderNumber){
    let params = {
      orderNumber: orderNumber,
      timestamp: new Date().getTime() 
    }
    let url = `${warehouseAPI}billing-charges-by-order-number`;
    let res = await axios.get(url,  {params: params});
    return res.data;
  },
  async getPalletisedInboundsBilling(params){
    let url = `${warehouseAPI}palletised-inbounds-billing`;
    let res = await axios.get(url,  {params: params});
    return res.data.data;
  },
  async getPalletwaysConsumableTariffs(){
    let url = `${warehouseAPI}palletways-consumable-tariffs`;
    let res = await axios.get(url);
    return res.data.data;
  },
  async bulkUpdateCompanyRates(reqBody){
    let url = `${warehouseAPI}bulk-update-company-rates`;
    let res = await axios.put(url, reqBody);
    return res.data.data;
  },
  async getInvoiceEmails(companyId){
    let url = `${warehouseAPI}invoice-email`;
    let res = await axios.get(url, {params: {companyId: companyId}});
    return res.data.data;
  },
  async insertInvoiceEmail(reqBody){
    let url = `${warehouseAPI}invoice-email`;
    let res = await axios.post(url, reqBody);
    return res.data.data;
  },
  async deleteInvoiceEmail(reqBody){
    let url = `${warehouseAPI}invoice-email`;
    let res = await axios.delete(url, {data:reqBody});
    return res.data.data;
  },
  async sendXeroBillingEmail(reqBody){
    let url = `${warehouseAPI}send-xero-billing-email`;
    let res = await axios.post(url, reqBody);
    return res.data.data;
  },
  async getCompanyBillingSettings(companyId, companyCode = null){
    let url = `${warehouseAPI}company-billing-settings`;
    let params = {
      timestamp: new Date().getTime()
    }
    if(companyId){
      params.companyId = companyId
    }
    if(companyCode){
      params.companyCode = companyCode
    }
    let res = await axios.get(url, {params: params});
    return res.data.data;
  },
  async saveCompanyBillingSettings(reqBody){
    let url = `${warehouseAPI}company-billing-settings`;
    let res = await axios.post(url, reqBody);
    return res.data.data;
  },
  async checkDuplicateCompanyBillingCharges(reqBody){
    let url = `${warehouseAPI}check-duplicate-company-billing-charges`;
    let res = await axios.post(url, reqBody);
    return res.data.data;
  },
  async getHistoricCompanyRates(companyId){
    let url = `${warehouseAPI}historic-company-rates`;
    let res = await axios.get(url, {params: {companyId: companyId, timestamp: new Date().getTime()}});
    return res.data.data;
  },
  async applyMinimumCharge(reqBody){
    let url = `${warehouseAPI}apply-minimum-charge`;
    let res = await axios.post(url, reqBody);
    return res.data.data;
  },
  async getFusionLicenceCharge(companyId, dateFrom, dateTo){
    let url = `${warehouseAPI}fusion-licence-charge`;
    const params = {
      companyId: companyId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      timestamp: new Date().getTime(),
    }
    let res = await axios.get(url, {params: params});
    return res.data.data;
  },
  async validateAdhocCharge(companyId, chargeDate){
    let url = `${warehouseAPI}validate-adhoc-charge`;
    let res = await axios.get(url, {params: {companyId: companyId, chargeDate: chargeDate, timestamp: new Date().getTime()}});
    return res.data.data;
  },

  
};

function buildUrl(
  company,
  dateFrom = new Date().toISOString().substr(0, 10),
  dateTo = new Date().toISOString().substr(0, 10)
) {
 
  let billingURL = `${warehouseAPI}generic-billing?dateFrom=${dateFrom}&dateTo=${dateTo}&companyId=${company.companyId}&timestamp=${new Date().getTime()}`;
  
 
    return billingURL;

}
